<template>
  <LayoutWithoutPadding>
    <div class="flex justify-center w-full">
      <LoginForm
        v-if="!isAuthenticated()"
        @onLogin="setLoginParameters"
        @onAppleAuth="loginWithSso('apple')"
        @onGoogleAuth="loginWithSso('google')"
        @onForgotPassword="showModal('ForgotPassword')"
        :is-loading="isLoading"
        show-sso-apple
        show-sso-google
      />
      <HCaptcha
        ref="hCaptchaRef"
        @onChallengeExpired="onClose"
        @onClosed="onClose"
        @onError="onError"
        @onExpired="onClose"
        @onVerify="onVerify"
      />
    </div>
  </LayoutWithoutPadding>
</template>

<script>
import USER_LOGIN from '@/graphql/mutations/UserLogin.gql';

import { LayoutWithoutPadding } from '@/components/misc';
import { LoginForm } from '@/components/authentication';
import HCaptcha from '@/components/misc/HCaptcha';

import { isApiError } from '@/utils/error-handler';

export default {
  name: 'Login',
  components: {
    HCaptcha,
    LayoutWithoutPadding,
    LoginForm,
  },

  data: function () {
    return {
      captcha: '',
      loginFormData: null,
      isLoading: false,
    };
  },

  async mounted() {
    if (this.isAuthenticated()) {
      return this.$router.push('/').catch(()=>{});
    }
    await this.$store.dispatch('events/track', {
      event: 'SIGN_IN_VIEWED',
    });
  },

  methods: {
    async loginWithSso(origin) {
      const authToken = await this.ssoGetAuthToken(origin);
      if (!authToken) {
        return;
      }

      this.isLoading = true;
      try {
        const authResult = await this.ssoAuth(authToken, origin);
        await this.handleLogin(authResult);
      } catch (err) {
        await this.handleLoginError(err, {
          email: null,
          keepSignedIn: true,
          ssoAuthToken: authToken,
          ssoOrigin: origin
        });
      } finally {
        this.isLoading = false;
      }
    },

    async loginWithPassword() {
      const { email, password, keepSignedIn, } = this.loginFormData;

      const { captcha } = this.$data;
      try {
        this.isLoading = true;
        const response = await this.apolloApiCall({
          mutation: USER_LOGIN,
          variables: {
            email,
            password,
            keep_me: keepSignedIn,
            captcha,
            twofa: '',
          }
        });
        const data = response.data['_login'];
        await this.handleLogin(data);
      } catch (err) {
        await this.handleLoginError(err, { email, password, keepSignedIn });
      }
    },

    async handleLoginError(err, authData) {
      this.$store.commit('ui/setState', { stateName: 'initialLoading', stateData: false });
      if (isApiError(err)) {
        if (err['error_code'] === 'invalid_captcha') {
          return this.onSubmit();
        }
        this.isLoading = false;
        if (err['error_code'] === 'invalid_2fa_code') {
          return this.showModal('TwoFactorAuthenticationVerification', { authData });
        } else if (err['error_code'] === 'web_requires_user_flag') {
          return this.showModal('WebRequiresUserFlag');
        } else if (err['error_code'] === 'must_agree_to_tos') {
          return this.showModal('UserAgreement', { authData, customClose: this.tosDisagree });
        }
      }
      this.isLoading = false;

      // This is to prevent showing an error to the user when he logs in
      if (err.message !== 'please_verify_your_email') {
        await this.showError(err);
      }
    },

    onClose() {
      this.isLoading = false;
    },

    onError() {
      this.isLoading = false;
    },

    onSubmit() {
      this.$refs.hCaptchaRef.execute();
    },

    async onVerify(captcha) {
      this.captcha = captcha;
      await this.loginWithPassword();
    },

    async setLoginParameters({ email, password, keepSignedIn }) {
      this.loginFormData = { email, password, keepSignedIn };
      await this.loginWithPassword();
    },
  },
};
</script>

<style scoped>

</style>
