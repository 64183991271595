<template>
  <form class="login-form" @submit.prevent="login">
    <!-- Header -->
    <div class="headline-medium">
      {{ $t('navigation.login') }}
    </div>

    <div>
      <!-- Inputs -->
      <div class="w-full mt-s24">
        <CustomInput
          data-cy="input-email"
          data-testid="input-email"
          ref="emailRef"
          v-model="email"
          :placeholder="$t('email.address')"
          :lp-ignore="false"
          type="email"
        />

        <CustomInput
          class="mt-s16"
          data-cy="input-password"
          data-testid="input-password"
          v-model="password"
          :placeholder="$t('password.label')"
          :lp-ignore="false"
          :type="passwordVisible ? 'text' : 'password'"
        >
          <template v-slot:icon>
            <div class="body-text-x-medium cursor-pointer" @click="togglePasswordVisible">
              {{ $t(`password.${passwordVisible ? 'hide' : 'show'}`) }}
            </div>
          </template>
        </CustomInput>
      </div>

      <div>
        <!-- Forgot password -->
        <div>
          <div
            @click="$emit('onForgotPassword')"
            class="mt-s16 text-text-positive body-text-x-large link-text hover:opacity-70"
          >
            {{ $t('password.forgot') }}
          </div>
        </div>

        <!-- Keep me signed in -->
        <div class="mt-s32 flex items-center">
          <Checkbox
            data-cy="checkbox-keep-signedin"
            data-testid="checkbox-keep-signedin"
            v-model="keepSignedIn"
            :label="$t('authentication.keep_me_signed_in')"/>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="mt-s32">
      <ButtonV2
        data-cy="button-login"
        data-testid="button-login"
        :inactive="!email || !password || isLoading"
        :label="$t('navigation.login')"
        submit
        size="medium"
        wide
      />
      <ButtonV2
        data-cy="button-signup"
        data-testid="button-signup"
        @onClick="goToInternalPageGlobal('/sign-up')"
        :label="$t('navigation.signup')"
        class="mt-s16"
        version="secondary"
        size="medium"
        wide
      />
    </div>

    <!-- SSO -->
    <LineSeparator v-if="showSsoApple || showSsoGoogle" :label="$t('or')"/>
    <ButtonV2
      data-testid="button-signup-apple"
      data-cy="button-signup-apple"
      class="mt-s16"
      v-if="showSsoApple"
      @onClick="$emit('onAppleAuth')"
      :label="$t('sso.signin_apple')"
      version="secondary"
      size="medium"
      icon="apple"
      icon-style="fab"
      wide
    />
    <ButtonV2
      data-testid="button-signup-google"
      data-cy="button-signup-google"
      v-if="showSsoGoogle"
      @onClick="$emit('onGoogleAuth')"
      :label="$t('sso.signin_google')"
      class="mt-s16"
      version="secondary"
      size="medium"
      icon="google"
      icon-style="fab"
      wide
    />
  </form>
</template>

<script>
import { ButtonV2, CustomInput, LineSeparator } from '@/components/misc';
import Checkbox from '@/components/misc/Checkbox';

export default {
  name: 'LoginForm',
  components: {
    ButtonV2,
    Checkbox,
    CustomInput,
    LineSeparator,
  },

  data: function () {
    return {
      email: '',
      keepSignedIn: true,
      password: '',
      passwordVisible: false,
    };
  },

  methods: {
    login() {
      this.$emit('onLogin', {
        email: this.email,
        keepSignedIn: this.keepSignedIn,
        password: this.password,
      });
    },

    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    showSsoApple: {
      type: Boolean,
      default: false,
    },

    showSsoGoogle: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
  .login-form {
    @apply my-s48 px-s20;
    width: 100%;
    max-width: 375px;
  }
</style>
